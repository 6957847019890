#survey-form {
  .form-check-inline {
    display: block;
  }
}

.pl-special-theme-home-featured {
  display: none;
}
.pl-special-theme {
  .pl-featured {
    position: relative;
    .pl-special-theme-home-featured {
      position: absolute;
      top: 50px;
      left: 50px;
      @media (min-width: 992px) {
        display: block;
      }
    }
  }

  .pl-cart-special {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .products article:nth-child(4n + 3) .card .pl-special-theme-product img {
    display: block;
  }

  .pl-special-theme-product {
    position: absolute;
    right: 0;
    top: 4px;
    img {
      display: none;
    }
  }

  #footer {
    .footer__before {
      position: relative;
    }
    .pl-special-theme-footer {
      position: absolute;
      right: 50px;
      bottom: 100%;
      z-index: -1;
      @media (max-width: 991.98px) {
        display: none;
      }
    }
  }

  .pl-header {
    .pl-special-theme-page img {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: -1;
      .page-category & {
        top: 50px;
        left: 10px;
      }
      @media (max-width: 991.98px) {
        display: none;
      }
    }
  }
  &.page-category {
    .pl-header {
      .pl-special-theme-page img {
        top: 65px;
        left: 10px;
      }
    }
  }
}
#module-prolasercustom-switchre .pl-custom-grid .title
{
  font-size: 1rem!important;
}
.content-img
{
  width:35%;
  margin-right: 2rem;
}
.bloc-assistance
{
  margin-right: 2rem;
  height: 3rem;
}
.logo-fsc
{
  height:4rem;
}
.gamme-card-max-width
{
  max-width: 70%;
  height: auto;
}
.new-bloc-rotate
{
  transform: rotate(-15deg);
  background-color: #fff;
  position: absolute;
  top: 5px;
  left: -70px;
}
.new-bloc-rotate h2
{
  color: #21587b;
  text-transform: uppercase;
  padding: 10px 400px 10px 100px;
}
.gamme-card-p
{
  font-size: 1.25rem !important;
  font-weight: bold;
}
.img-laser-w
{
  margin-top: -5rem;
}
.new-title
{
  color: #fff;
}
.container-bloc-icons .card
{
  background-color: transparent!important;
}
.span-italic span{
  font-size: 0.75rem;
}

#module-prolasercustom-switchre
{
  color:#3c3c3c;
}
#module-prolasercustom-switchre #content-wrapper section:nth-child(5)
{
  margin-top: 7rem;
}
#module-prolasercustom-switchre section.my-5
{
  margin-top: 7rem!important;
}
.paddingp
{
  padding-left: 20rem;
  padding-right: 20rem;
}
@media screen and (max-width: 720px) {
  .img-laser-w
  {
    margin-top: 0;
  }
}
